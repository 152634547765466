import React, { FC } from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import NextLink, { LinkProps as NxtLinkProps } from 'next/link';

export const Link: FC<MuiLinkProps & NxtLinkProps> = ({
  href,
  as,
  replace,
  scroll: _,
  sx,
  shallow,
  passHref,
  prefetch,
  locale,
  ...muiProps
}) => {
  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={false}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      <MuiLink
        data-href={href}
        sx={{
          ...sx,
          cursor: 'pointer'
        }}
        {...muiProps}
      />
    </NextLink>
  );
};
